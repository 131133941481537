<template lang="pug">
include ../../../../configs/template
form.row
  div.col-12
    +field-validation('body.additional_info.number', 'number', '["required"]')(:readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.position','listMedicalPosition', 'position', '"name_eng"', '["required"]')
  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.limitation', 'listLimitation', 'limitation', '"name_eng"', '["required"]')
  div(v-if="isCheckDocument").col-12
    +field-validation('body.additional_info.other_medical_institution', 'medicalInstitution', '["required", "alphaEN"]')
  div(v-if="isCheckDocument").col-12
    +field-validation('body.additional_info.other_doctor', 'doctor', '["required", "alphaEN"]')
  div.col-sm-12.col-md-6
    +date-picker-validation('body.additional_info.date_start','dateIssue','dateObjectStart','["required", "minValue", "maxValue"]', 'isCheckDocument','!isCheckDocument')(:readonly="isCheckDocument" :max="new Date().toISOString()")
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +date-picker-validation('body.additional_info.date_end','dateTermination','dateObjectEnd','["required", "minValue", "maxValue"]')(:readonly="isCheckDocument" )
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading.check") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm") {{$t('btn.clear')}}

</template>
<script>
import { verificationISC } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'

const initBody = () => (
  {
    additional_info: {
      number: null,
      position: null,
      date_end: null,
      date_start: null,
      other_doctor: null,
      other_medical_institution: null
    }
  }
)

export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      isCheckDocument: false,
      isLoading: {
        save: false,
        check: false
      }
    }
  },
  computed: {
    ...mapState({
      listMedicalPosition: state => state.directory.positionMedical,
      listLimitation: state => state.directory.limitations,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateObjectStart () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateObjectEnd () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    }
  },
  validations () { return verificationISC.medical(this) },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationISC']),
    async saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading.save = true
      let data = {
        body: { ...this.body },
        media: {
          name: 'MedicalDoc',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentISC' })
      this.isLoading.save = false
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading.check = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationISC({ id: statementId, body: { additional_info: { ...clearBody(this.body.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading.check = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
    }
  }

}
</script>
